import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"

export default function PrivacyPolicy({ data }) {
  const sections = data.sections.relationships.field_sections
  const section = sections[0]
  const blocks = section.relationships.field_section_blocks
  const privacyHeader = blocks[0]
  const twoColumnBlock = blocks[1].relationships
  return (
    <Layout>
      <div className="overflow-hidden bg-white mx-auto max-w-xl lg:max-w-7xl pb-16 pt-32 px-4 sm:px-6 lg:px-8 md:pt-40 lg:pt-48 lg:pb-24">
        <div className="relative z-10 mb-8 md:mb-2">
          <div className="max-w-prose text-base lg:max-w-none">
            <p className="font-semibold leading-6 text-sky-700">{privacyHeader.field_header_tagline}</p>
            <h1 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-sky-900 sm:text-4xl">
              {privacyHeader.field_header_title}
            </h1>
          </div>
        </div>
        <div className="relative">
          <div className="relative md:py-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-16">
              <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none"
                   dangerouslySetInnerHTML={{ __html: twoColumnBlock.field_column_one[0].field_news_text_body.processed }} />
              <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0"
                   dangerouslySetInnerHTML={{ __html: twoColumnBlock.field_column_two[0].field_news_text_body.processed }} />
            </div>
          </div>
        </div>
      </div>
     </Layout>
  )
}

export const Head = ({ location, data }) => {
  const canonicalUrl = `${process.env.GATSBY_SITE_URL}privacy-policy/`
  const metaDescription = data.sections?.field_metatags?.description ?? ``
  return (
    <>
      <meta name="description" content={metaDescription} />
      <title>Privacy policy | DEC Housing</title>
      <link rel="canonical" href={canonicalUrl} />
    </>
  )
}

export const query = graphql`
  query PrivacyPageQuery {
    sections: nodePage(drupal_internal__nid: {eq: 11}) {
      field_metatags {
        description
      }
      relationships {
        field_sections {
          ... on paragraph__section {
            relationships {
              field_section_blocks {
                ...HeaderFields
                ...TwoColumnBlockFields
              }
            }
          }
        }
      }
      title
    }  
  }
`



